import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout.js";
import { PageH1 } from "./../styledcomponents/layoutstyles";
import { StarUl } from "./../styledcomponents/samplestyles";

const FreeSamples = () => {
  const sampleList = useStaticQuery(graphql`
    query sampleQuery {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              order
              type
              slug
            }
          }
        }
      }
    }
  `)
    .allMarkdownRemark.edges.map((x) => x.node.frontmatter)
    .filter((x) => x.type === "sample")
    .sort((a, b) => a.order - b.order);
  return (
    <Layout title={`Ring On Deli: Free Samples!`}>
      <PageH1>Free Samples!</PageH1>
      <StarUl>
        {sampleList.map((sample, index) => (
          <li key={index}>
            <Link to={`/text/${sample.slug}`}>{sample.title}</Link>
          </li>
        ))}
      </StarUl>
    </Layout>
  );
};

export default FreeSamples;
